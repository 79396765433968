import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import HowItWorksFunc from "./Cards";
import FAQ from "./FAQ";
import SlideSHow from "./SlideShow";
import AnimatedNumber from "react-animated-number";
import Chemitek from "../images/chemitek.png";

const MainContent = () => {
  const initialValue = 100000;
  const currentValue = 192593;
  return (
    <Box spacing={2} width="100%">
      <SlideSHow />
      <Grid container className="numbers">
          <AnimatedNumber
            initialValue={initialValue}
            value={currentValue}
            stepPrecision={0}
            style={{
              transition: "0.8s ease-out",
              fontSize: 40,
              transitionProperty: "background-color, color, opacity",
              fontFamily: "Roboto",
            }}
            duration={1000}
            formatValue={(n) => Intl.NumberFormat("bg-BG").format(n)}
          />
          <Typography variant="h5" marginLeft="1%">
            изчистени соларни панела в България с препаратите на
          </Typography>
          <img src={Chemitek} height="105px" width="205px" alt="chemitek" />
      </Grid>
      <HowItWorksFunc />
      <FAQ />
    </Box>
  );
};
export default MainContent;
