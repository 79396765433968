import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import "./NavBar.css";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import Chemitek from "../images/chemitek.png";
import SolarClean0 from "../images/solarCleano.svg";
import FacebookIcon from "@mui/icons-material/Facebook";

function FooterBasic() {
  return (
    <Box
      px={{ xs: 3, sm: 5 }}
      py={{ xs: 5, sm: 5 }}
      bgcolor="#273649"
      color="whitesmoke"
      mt={2}
      mb={1}
      display="flex"
      flexDirection="column"
    >
      <Grid
        container
        textAlign="left"
        spacing={2}
        sx={{ justifyContent: "center" }}
      >
        <Grid item xs={12} sm={8} md={4} lg={2} xl={2}>
          <Box>
            <Link to="/" className="myLinkFooter">
              {" "}
              Услуги{" "}
            </Link>
          </Box>
          <Box mt={2}>
            <Link to="/contacts" className="myLinkFooter">
              {" "}
              Контакти{" "}
            </Link>
          </Box>
          <Box mt={2}>
            <Link to="/agents" className="myLinkFooter">
              {" "}
              ChemiTek - препарати{" "}
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={8} md={4} lg={2} xl={2}>
          <Box>
            <MarkEmailReadIcon />
            <Link to="/" className="myLinkFooter">
              {" "}
              info@solartc.eu{" "}
            </Link>
          </Box>
          <Box>
            <PhoneIphoneIcon />
            <Link to="/" className="myLinkFooter">
              {" "}
              +359 888 99 59 58
            </Link>
          </Box>
          <Box>
            <PhoneIphoneIcon />
            <Link to="/" className="myLinkFooter">
              {" "}
              +359 888 33 92 92{" "}
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={2} xl={2}>
          <Box display="flex" flexDirection="row" gap="3%">
            <img src={Chemitek} height="90px" width="205px" alt="chemitek" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={2} xl={2}>
          <Box display="flex" flexDirection="row" gap="3%">
            <img src={SolarClean0} height="80px" width="205px" alt="chemitek" />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ justifyContent: "center", alignItems: "center" }}>
        <Box>
          {" "}
          <Link
            to="https://www.facebook.com/profile.php?id=61553058427584"
            className="myLinkFooter"
            target="_blank"
          >
            <FacebookIcon />
          </Link>
          <Typography>2023 Eс Ти Си Дивелъпмънт ЕООД ©</Typography>
        </Box>
      </Box>
      <Box></Box>
    </Box>
  );
}

export default FooterBasic;
