import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  NativeSelect,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import bio from "../images/bio.png";
import drops from "../images/drops.jpg";
import tuv from "../images/tuv-sud.png";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import "./AgentInfo.css";
import { CSSTransition } from "react-transition-group";
import NewOrder from "./Order";
import Popup from "reactjs-popup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function AgentInfo() {
  const location = useLocation();
  const agent = location.state.agent;
  const [price, setPrices] = useState("");
  const [show, setShow] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const isRowBased = useMediaQuery("(min-width: 800px)");

  const handleOpen = () => {
    setOpenOrder(true);
  };

  useEffect(() => {
    setTimeout(() => setShow(true), 1);
  }, []);

  const handleChange = (event) => {
    setPrices(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        maxWidth: "860px ",
      }}
    >
      <Box
        minHeight="470px"
        display="flex"
        minWidth="800px"
        sx={{
          flexDirection: isRowBased ? "row" : "column",
        }}
      >
        <Card
          sx={{
            width: "40%",
            marginTop: "2%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
          elevation={0}
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h3" color="whitesmoke" textAlign="left">
              {agent.name}
            </Typography>
            <Box sx={{ mb: "5%" }}>
              <Link to="/agents">
                <Button
                  sx={{
                    ":hover": {
                      bgcolor: "primary.main",
                      color: "white",
                    },
                  }}
                  startIcon={<KeyboardArrowLeftIcon />}
                >
                  Препарати
                </Button>
              </Link>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CSSTransition
              in={show}
              timeout={1000}
              classNames="alert"
              onExited={() => setShow(true)}
            >
              <CardMedia
                sx={{ height: "442px", width: "287px", marginLeft: "2%" }}
                component="img"
                image={agent.image}
                title={agent.nameBulgarian}
              />
            </CSSTransition>
            <CardContent>
              <Link to={agent.instructions} target="_blank">
                <Button
                  sx={{
                    ":hover": {
                      bgcolor: "primary.main",
                      color: "white",
                    },
                  }}
                  startIcon={<FileDownloadRoundedIcon />}
                >
                  Инструкции за употреба
                </Button>
              </Link>
            </CardContent>
          </Box>
        </Card>

        <Card
          sx={{
            maxWidth: "40%",
            marginTop: "2%",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Typography variant="h5" color="text.secondary" padding="10px">
              {agent.nameBulgarian}
            </Typography>
            <Typography variant="body2" color="text.secondary" padding="10px">
              Разреждане: {agent.dilution}
              <br />
              Опаковка: 2кг, 5кг
            </Typography>
            <Typography
              variant="h6"
              color="text.secondary"
              padding="10px"
              textAlign="justify"
            >
              Как работи?
            </Typography>
            <Typography variant="h7" color="text.secondary" padding="10px">
              {agent.info.intro}
            </Typography>
            <Typography variant="h6" color="text.secondary" padding="10px">
              Предимства
            </Typography>
            <Typography variant="h7" color="text.secondary" padding="10px">
              {agent.info.benefits}
            </Typography>
            <Typography variant="h6" color="text.secondary" padding="10px">
              Приложимо за:
            </Typography>
            <Typography variant="h7" color="text.secondary" padding="10px">
              {agent.info.apply}
            </Typography>
            <Typography variant="h6" color="text.secondary" padding="10px">
              Цена:
            </Typography>
            <List>
              {agent.price.map((item, index) => {
                return (
                  <ListItem sx={{ color: "text.secondary" }} key={index}>
                    {item}
                  </ListItem>
                );
              })}
            </List>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5%",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  padding: "5px",
                  marginBottom: "1%",
                }}
              ></Box>
              <Box sx={{ alignSelf: "end", padding: "5px" }}>
                <Link to="/newOrder">
                  <Button
                    sx={{
                      color: "#273649",
                      borderColor: "#273649",
                      ":hover": {
                        bgcolor: "#273649",
                        borderColor: "#273649",
                        color: "white",
                      },
                    }}
                    variant="outlined"
                    size="small"
                    endIcon={<ShoppingCartCheckoutIcon />}
                  >
                    Поръчай сега
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </Card>
        <Card
          sx={{
            maxWidth: "20%",
            marginTop: "3%",
            display: "flex",
            flexDirection: "column",
            padding: "2%",
          }}
          elevation={0}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isRowBased ? "column" : "row",
              textAlign: "left",
              columnGap: "1%",
              marginTop: "7%",
              alignSelf: "center",
            }}
          >
            <CardMedia
              sx={{ height: "50px", width: "50px", margin: "auto" }}
              component="img"
              image={bio}
              title="Биоразградим"
            />
            <CardMedia
              sx={{
                height: "50px",
                width: "50px",
                margin: "auto",
                marginTop: "10px",
              }}
              component="img"
              image={drops}
              title="Концентрат"
            />
            <CardMedia
              sx={{
                height: "50px",
                width: "50px",
                margin: "auto",
                marginTop: "10px",
              }}
              component="img"
              image={tuv}
              title="Tuv Sud сертификат"
            />
          </Box>
        </Card>
      </Box>
      <Popup
        open={openOrder}
        lockScroll={true}
        position="center"
        modal
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="popup-content">
          <IconButton
            sx={{
              mb: 3,
              mt: 5,
              color: "#273649",
              borderColor: "#273649",
              marginLeft: "100%",
            }}
            variant="outlined"
            type="submit"
            onClick={() => setOpenOrder(false)}
          >
            <HighlightOffIcon />
          </IconButton>
          {<NewOrder />}
        </div>
      </Popup>
    </Paper>
  );
}

export default AgentInfo;
