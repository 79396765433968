import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../index.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box } from "@mui/system";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddIcon from "@mui/icons-material/Add";
import photo1 from "../images/top-cart.jpg";
import axios from "axios";
import emailjs from "@emailjs/browser";

const NewOrder = () => {
  const [agents, setAgents] = useState([]);
  const name = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const address = useRef(null);

  const form = useRef();
  // const orderId = Math.round(Math.random() * 400);
  const [qty, setQty] = useState("");
  const [agent, setAgent] = useState("");
  const [pack, setPack] = useState("");

  const [rows, setRows] = useState([]);

  const [userResult, setUserResult] = useState(null);
  const [orderResult, setOrdeResult] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    axios
      .get("/db/agents.json")
      .then((res) => setAgents(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleChange = (event) => {
    setQty(event.target.value);
  };

  const handleChangeAgent = (event) => {
    setAgent(event.target.value);
  };

  const handleChangePack = (event) => {
    setPack(event.target.value);
  };

  // async function postData() {
  //   const postData = {
  //     orderId: orderId.value,
  //     title: name.current.value,
  //     imageUrl: phone.current.value,
  //     url: email.current.value,
  //     quantity: product_quantity.current.value,
  //     addInfo: addInfo.current.value,
  //     user: userId,
  //     userName: userName,
  //     address: userAddress,
  //   };
  //   try {
  //     const res = await fetch(`${baseURL}/orders`, {
  //       method: "POST",
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(postData),
  //     });
  //     if (!res.ok) {
  //       const message = `An error has occured:`;
  //       throw new Error(message);
  //     }
  //     const result = (
  //       <Typography
  //         variant="h7"
  //         sx={{ color: "#272d2f", fontFamily: "Roboto" }}
  //       >
  //         Your order has been submitted!{" "}
  //         <Link to="/my-profile" className="myLink-content">
  //           Back to my orders
  //         </Link>
  //       </Typography>
  //     );

  //     setUserResult(result);
  //   } catch (err) {
  //     setUserResult(err.message);
  //   }
  // }

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_hclqgia",
        "template_zbp58ca",
        form.current,
        "Oyja5w33bILQvKRxv"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const validationSchema = yup.object({
    name: yup.string().required("Задължително поле"),
    address: yup
      .string()
      .min(10, "Твърде кратко!")
      .required("Задължително поле"),
    phone: yup
      .string("Въведете своя мобилен номер")
      .min(10, "Невалиден номер")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Невалиден номер"
      )
      .required("Задължително поле"),
    email: yup.string().email("Невалиден e-mail").required("Задължително поле"),
  });

  const newRow = {
    product: agent.name,
    kg: pack,
    pcs: qty,
    image: agent.image,
    price: agent.priceNumber,
    abb: agent.abb,
  };

  const addRow = () => {
    if (newRow.product === undefined) {
      setUserResult("Моля, изберете препарат!");
    } else {
      setRows([...rows, newRow]);
      setAgent("");
      setPack("");
      setQty("");
      setUserResult("");
    }
  };

  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      phone: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      formik.resetForm(values);
      sendEmail();
      setOrdeResult("Благодарим Ви за изпратената поръчка!");
    },
  });

  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        xl={12}
        display="flex"
        alignitems="stretch "
        justifyContent="center"
      >
        <Paper
          sx={{
            "& .MuiTextField-root": { m: 1, width: "80%" },
          }}
          autoComplete="off"
          elevation={0}
        >
          <img
            src={photo1}
            alt="cover"
            height="150px"
            className="orderImg"
          ></img>
          <Typography
            variant="h6"
            sx={{ fontFamily: "Roboto" }}
            width={400}
            margin="auto"
          >
            Завършете поръчката, като изберете желания от Вас продукт.
          </Typography>
          {userResult && (
            <Typography
              variant="h6"
              color="orangered"
              sx={{ fontFamily: "Roboto" }}
              width={400}
              margin="auto"
            >
              <pre>{userResult}</pre>
            </Typography>
          )}
          <form
            ref={form}
            onSubmit={(e) => {
              formik.handleSubmit(e);
            }}
            autoComplete="off"
            sx={{
              width: "80%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                margin: "auto",
                justifyContent: "center",
              }}
              className="orderStyleSelect"
            >
              <FormControl
                sx={{ m: 1, width: "50%" }}
                size="small"
                variant="standard"
              >
                <InputLabel id="demo-simple-select-label">
                  Изберете препарат
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={agent}
                  label="Брой"
                  onChange={handleChangeAgent}
                >
                  {agents.map((item, index) => (
                    <MenuItem value={item} key={index}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{ m: 1, width: 100 }}
                size="small"
                variant="standard"
              >
                <InputLabel id="demo-simple-select-label">кг</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={pack}
                  label="Брой"
                  onChange={handleChangePack}
                >
                  <MenuItem value={"2kg"}>2кг</MenuItem>
                  <MenuItem value={"5kg"}>5кг</MenuItem>
                  <MenuItem value={"20kg"}>20кг</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Брой"
                type="number"
                variant="standard"
                size="small"
                value={qty}
                onChange={handleChange}
                style={{ width: 100 }}
              />
              <Button
                sx={{
                  mt: 3,
                  color: "#273649",
                  borderColor: "#273649",
                  ":hover": {
                    bgcolor: "#273649",
                    borderColor: "#273649",
                    color: "white",
                  },
                }}
                variant="outlined"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  addRow();
                }}
              >
                Добави
              </Button>
            </Box>
            <Box>
              <Typography variant="h6" mt={3} color="#cd7700">
                Вашата поръчка е:
              </Typography>
              <Box sx={{ overflow: "auto" }}>
                <Box
                  sx={{
                    width: "80%",
                    display: "table",
                    tableLayout: "fixed",
                    margin: "auto",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    elevation={0}
                    sx={{ margin: "auto", width: "70%" }}
                  >
                    <Table sx={{ minWidth: 100 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Препарат</TableCell>
                          <TableCell>Опаковка</TableCell>
                          <TableCell>Ед.Цена</TableCell>
                          <TableCell>Брой</TableCell>
                          <TableCell>Общо</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.product}>
                            <TableCell>
                              <TextField
                                name="product"
                                value={row.abb}
                                variant="standard"
                                size="small"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                              <img
                                src={row.image}
                                alt="препарат за почистване на соларни панели"
                                height="60px"
                                width="40px"
                              ></img>
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                name="kg"
                                value={row.kg}
                                variant="standard"
                                size="small"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {row.kg === "2kg"
                                ? row.price[0]
                                : row.kg === "5kg"
                                ? row.price[1]
                                : row.price[2]}
                              лв
                            </TableCell>
                            <TableCell align="center">
                              <TextField
                                name="pcs"
                                value={row.pcs}
                                variant="standard"
                                size="small"
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {row.kg === "2kg"
                                ? (row.pcs * row.price[0]).toFixed(2)
                                : row.kg === "5kg"
                                ? (row.pcs * row.price[1]).toFixed(2)
                                : (row.pcs * row.price[2]).toFixed(2)}
                              лв
                            </TableCell>
                            <TableCell>
                              <IconButton
                                sx={{
                                  color: "#273649",
                                  borderColor: "#273649",
                                }}
                                variant="outlined"
                                type="submit"
                                onClick={() => deleteRow()}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </Box>
            <Box width="70%" margin="auto">
              <Box
                sx={{
                  display: "flex",
                  width: "80%",
                  margin: "auto",
                }}
                className="orderStyle"
              >
                <Typography
                  sx={{ mt: 3, mb: 2 }}
                  variant="h6"
                  className="orderTitle"
                >
                  Попълнете своите лични данни и ние ще се свържем с Вас.
                </Typography>
              </Box>
              <Box className="orderStyle">
                <TextField
                  id="name"
                  label="Име и Фамилия"
                  name="name"
                  variant="outlined"
                  size="small"
                  inputRef={name}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                  id="email"
                  label="E-mail"
                  name="email"
                  variant="outlined"
                  size="small"
                  inputRef={email}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                  id="phone"
                  label="Телефон"
                  name="phone"
                  variant="outlined"
                  size="small"
                  inputRef={phone}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Box>
              <Box className="orderStyle">
                <TextField
                  id="address"
                  label="Адрес за доставка"
                  name="address"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={4}
                  inputRef={address}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Box>
            </Box>
            <Box className="orderStyle">
              <Button
                sx={{
                  mt: 3,
                  color: "#273649",
                  borderColor: "#273649",
                  ":hover": {
                    bgcolor: "#273649",
                    borderColor: "#273649",
                    color: "white",
                  },
                }}
                variant="outlined"
                type="submit"
              >
                Потвърди поръчка
              </Button>
              {orderResult && (
                <Typography
                  variant="h6"
                  color="orangered"
                  sx={{ fontFamily: "Roboto" }}
                  width={400}
                  margin="auto"
                >
                  <pre>{orderResult}</pre>
                </Typography>
              )}
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewOrder;
