import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../index.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box } from "@mui/system";
import emailjs from "@emailjs/browser";
import Energy from "../images/energy.jpg";
import SendIcon from "@mui/icons-material/Send";
import DraftsIcon from "@mui/icons-material/Drafts";

const ContactForm = () => {
  const user_name = useRef(null);
  const user_email = useRef(null);
  const user_phoneNumber = useRef(null);
  const message = useRef(null);
  const [userResult, setUserResult] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const form = useRef();

  const sendEmail = (e) => {

    emailjs
      .sendForm(
        "service_hclqgia",
        "template_4ks5c3q",
        form.current,
        "Oyja5w33bILQvKRxv"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .min(2, "Твърде кратко!")
      .max(20, "Твърде дълго!")
      .required("Задължително поле"),
    email: yup.string().email("Невалиден e-mail").required("Задължително поле"),
    phoneNumber: yup
      .string("Въведете своя мобилен номер")
      .min(10, "Невалиден номер")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Невалиден номер"
      )
      .required("Задължително поле"),
    message: yup
      .string()
      .min(10, "Твърде кратко!")
      .required("Задължително поле"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      sendEmail(values);
      formik.resetForm();
      setUserResult("Благодарим Ви за изпратеното съобщение!")
    },
  });

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Grid item xs={12} sm={8} md={4} lg={10} xl={10}>
        <Paper
          sx={{
            "& .MuiTextField-root": { m: 1, width: "80%" },
            display: "flex",
            flexDirection: "row",
          }}
          autoComplete="off"
          elevation={0}
        >
          <Box sx={{ width: "40%", margin: "auto" }}>
            <DraftsIcon sx={{ fontSize: 60, color: "#fda726" }} />
            <img
              src={Energy}
              width="300"
              alt="соларен-панел"
              className="contacts-image"
            />
          </Box>
          <Box
            sx={{
              marginBottom: "3%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "5%",
              }}
            >
              <Typography variant="h6" sx={{ fontFamily: "Roboto" }}>
                Свържете се с нас
              </Typography>
              <Typography variant="h7" sx={{ fontFamily: "Roboto" }}>
                В най-кратък срок ще получите обратна връзка.
              </Typography>
            </Box>

            <form
              ref={form}
              onSubmit={(e) => {
                formik.handleSubmit(e);
              }}
              autoComplete="off"
            >
              {userResult && (
                <Typography>
                  {" "}
                  <pre>{userResult}</pre>
                </Typography>
              )}
              <TextField
                id="name"
                label="Име и фамилия"
                name="name"
                variant="outlined"
                inputRef={user_name}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                id="email"
                label="E-mail"
                variant="outlined"
                name="email"
                inputRef={user_email}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                id="phoneNumber"
                label="Телефон"
                variant="outlined"
                placeholder="08XX12345678"
                name="phoneNumber"
                onChange={formik.handleChange}
                inputRef={user_phoneNumber}
                value={formik.values.phoneNumber}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
              <TextField
                id="message"
                label="Съобщение"
                variant="outlined"
                name="message"
                multiline
                rows={4}
                onChange={formik.handleChange}
                inputRef={message}
                value={formik.values.message}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
              <Box>
                <Button
                  sx={{
                    color: "#273649",
                    borderColor: "#273649",
                    ":hover": {
                      bgcolor: "#273649",
                      borderColor: "#273649",
                      color: "white",
                    },
                    padding: "5px",
                    marginTop: "3%",
                    marginBottom: "3%",
                  }}
                  variant="outlined"
                  size="small"
                  endIcon={<SendIcon />}
                  type="submit"
                >
                  Изпрати
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
