import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import TuvSud from "../images/tuv-sud.png";
import "./Agents.css";

function Agents() {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    axios
      .get("/db/agents.json")
      .then((res) => setAgents(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box spacing={2}>
      <Box
        display="flex"
        flexDirection="column"
        marginLeft="3%"
        marginRight="3%"
      >
        <Typography variant="h3" align="right" color="whitesmoke">
          ПРЕПАРАТИ ЗА ПОЧИСТВАНЕ
        </Typography>
        <Typography variant="h7" align="left">
          Препаратите за почистване на <b>ChemiТek</b> допринасят за повишаване
          на производителността на Вашите соларни панели.
        </Typography>
        <Typography variant="h7" align="left">
          Като официален представител на <b>ChemiТek</b> за България, ние
          предоставяме за продажба следните продукти:
        </Typography>
      </Box>
      <Grid
        container
        minHeight="470px"
        flexDirection="row"
        justifyContent="center"
        gap="10%"
      >
        {agents.map((agent, key) => (
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            lg={2}
            xl={2}
            className="card"
            key={agent.id}
          >
            <Card
              sx={{
                marginTop: "3%",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
              }}
              elevation={0}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                marginBottom="2%"
                marginTop="5%"
                className="card-title"
              >
                {agent.name}
              </Typography>
              <div className="underLine"></div>
              <NavLink to="/agentInfo" state={{ agent }}>
                <CardMedia
                  sx={{ height: "230px", width: "150px", margin: "auto" }}
                  component="img"
                  image={agent.image}
                  title={agent.nameBulgarian}
                  className="card-media"
                />
              </NavLink>
              <CardContent>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  className="agent-title-bg"
                >
                  {agent.nameBulgarian}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        display="flex"
        flexDirection="row"
        margin="auto"
        justifyContent="center"
        alignItems="center"
      >
        <img src={TuvSud} height="60px" width="60px" alt="tuv" />
        <Typography variant="h7" align="right">
          Всички препарати са биоразградими, с неутрално pH и сертификат TÜV
          SÜD.
        </Typography>
      </Box>
    </Box>
  );
}

export default Agents;
