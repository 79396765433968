import React from "react";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./SlideShow.css";
import photo1 from "../images/photo1.avif";
import photo2 from "../images/photo2.avif";
import photo3 from "../images/photo3.avif";

const SlideShow = () => {
  return (
    <Zoom autoplay={true} indicators={true} duration={1700} arrows={false}>
      <div className="each-slide-effect">
        <div
          style={{
            backgroundImage: `url(${photo1})`,
          }}
          className="slide-item"
        ></div>
      </div>
      <div className="each-slide-effect">
        <div
          style={{
            backgroundImage: `url(${photo2})`,
          }}
          className="slide-item"
        >
        </div>
      </div>
      <div className="each-slide-effect">
        <div
          style={{
            backgroundImage: `url(${photo3})`,
          }}
          className="slide-item"
        >
        </div>
      </div>
    </Zoom>
  );
};

export default SlideShow;
