import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import "./FAQ.css";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

function FAQ() {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setShow(true), 2000);
  }, []);

  return (
    <div className="questions">
      <CSSTransition
        in={show}
        timeout={1000}
        classNames="alert"
        onExited={() => setShow(true)}
      >
        <Typography variant="h6" color="#273649" mb="10px">
          Имате някой от следните въпроси?
        </Typography>
      </CSSTransition>
      <Accordion sx={{ marginBottom: "10px" }} defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="#273649">Какво можем да Ви предложим?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign="left">
            {" "}
            Препарати за почистване на соларни панели - най-доброто решение да
            получите максимална производителност. ChemiTek препаратите ще Ви
            помогнат за справянето с всякакъв вид замърсяване.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Кои са ChemiTek и какво предлагат?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign="left">
            ChemiTek са компания базирана в Португалия. Специализирани и
            сертифицирани в устройчиви решения за поддръжка на соларни панели.
            Лидер на световния пазар. Като официален дистрибутор на ChemiTek за
            България - ние ще предложим цялата продуктова гама. За повече
            информация вижте <NavLink to="/agents">ТУК</NavLink>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ marginBottom: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Колко често се почистват соларните панели?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign="left">
            Производителите налагат почистване всеки 6 месеца. Факторите, които
            са определящи са: предназначението на соларните панели - собствени
            нужди, производство или с цел продажба; вид замърсяване на соларните панели - прах, полени, цимент, боя, лишеи, варовик и други.
            Ние ще Ви дадем правилната насока след направена консултация.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FAQ;
