import { Container, Grid } from "@mui/material";
import React, { useRef } from "react";
import { Route, Router, Routes } from "react-router-dom";
import "./App.css";
import "./index.css";
import FooterBasic from "./components/Footer";
import Navigation from "./components/NavBar";
import Main from "./components/Main";
import Agents from "./components/Agents";
import AgentInfo from "./components/AgentInfo";
import Services from "./components/Services";
import ContactForm from "./components/Contacts";
import handleSubmit from "./handles/handlesubmit";
import NewOrder from "./components/Order";

function App() {
  const dataRef = useRef();

  const submithandler = (e) => {
    e.preventDefault();
    handleSubmit(dataRef.current.value);
    dataRef.current.value = "";
  };

  return (
    <div className="App">
      <Navigation />
      <Container>
        <Grid
          container
          display="flex"
          alignItems="center"
          mt="20px"
          fullwidth="true"
        >
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/contacts" element={<ContactForm />} />
            <Route path="/agents" element={<Agents />} />
            <Route path="/agentInfo" element={<AgentInfo />} />
            <Route path="/services" element={<Services />} />
            <Route path="/newOrder" element={<NewOrder />} />
          </Routes>
        </Grid>
      </Container>
      {/* <div className="App">
      <form onSubmit={submithandler}>
        <input type= "text" ref={dataRef} />
        <button type = "submit">Save</button>
      </form>
    </div> */}
      <FooterBasic />
    </div>
  );
}

export default App;
