import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import "./AgentInfo.css";
import { Link } from "react-router-dom";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const HowItWorks = () => {
  return (
    <Grid container spacing={2} mt={0.1}>
      <Grid item xs={12} md={4} display="flex" alignitems="stretch">
        <Card
          sx={{
            backgroundColor: "rgb(242, 240, 241)",
            marginTop: "10px",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <CardHeader
            title="Какво можем да Ви предложим?"
            titleTypographyProps={{
              fontSize: 16,
            }}
            subheader={`Професионални решения за почистване на ФЕЦ`}
            subheaderTypographyProps={{
              fontSize: 14,
            }}
          ></CardHeader>
          <CardContent sx={{ margin: "auto", textAlign: "left" }}>
            <List sx={{ width: "100%" }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#657d86" }}>
                    <WaterDropIcon sx={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Продажба на сертифицирана техника и препарати за почистване на соларни панели" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#657d86" }}>
                    <CleaningServicesIcon sx={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Почистване на соларни панели" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#657d86" }}>
                    <ContentCutIcon sx={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Третиране на зелени площи около соларните панели" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: "#657d86" }}>
                    <RemoveRedEyeIcon sx={{ color: "white" }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Термовизионно заснемане с индустриален дрон" />
              </ListItem>
            </List>
          </CardContent>
          <Box>
            <Link to="./services">
              <Button sx={{ color: "#cd7700", textDecoration:"none" }} aria-label="delete">
                Научете повече
              </Button>
            </Link>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} md={4} display="flex" alignitems="stretch">
        <Card
          sx={{
            backgroundColor: "rgb(242, 240, 241)",
            marginTop: "10px",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <CardHeader
            title="Кои сме ние?"
            titleTypographyProps={{
              fontSize: 16,
            }}
            subheader={`За нас`}
            subheaderTypographyProps={{
              fontSize: 14,
            }}
          ></CardHeader>
          <CardMedia
            component="img"
            height="150"
            image="../images/111.jpg"
            alt="препарат за соларни панели"
            sx={{ margin: "auto" }}
          ></CardMedia>
          <CardContent sx={{ margin: "auto", textAlign: "justify" }}>
            <Typography
              sx={{ marginTop: "20px" }}
              variant="body2"
              color="black"
              justifyItems="left"
            >
              Специалисти в почистване на соларни панели.
              Официален представител на ChemiTek Португалия - лидер на световния
              пазар,който ще Ви предложи най-добър препарат за почистване на соларни панели. Чрез нас Вие
              ще получите най-добрата производителност от изградената от Вас
              фотоволтаична инсталация.
            </Typography>
          </CardContent>
          <Box>
            <Link to="./contacts">
              <Button sx={{ color: "#cd7700", textDecoration:"none" }} aria-label="delete">
                Контакти
              </Button>
            </Link>
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} md={4} display="flex" alignitems="stretch">
        <Card
          sx={{
            backgroundColor: "rgb(242, 240, 241)",
            marginTop: "10px",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
          }}
        >
          <CardHeader
            title="Разход за почистване на соларни панели"
            titleTypographyProps={{
              fontSize: 16,
            }}
            subheader={`Изготвяне на индивидуална оферта.`}
            subheaderTypographyProps={{
              fontSize: 14,
            }}
          ></CardHeader>
          <CardMedia
            component="img"
            height="150"
            image="../images/222.jpg"
            alt="препарат за соларни панели"
            sx={{ margin: "auto" }}
          ></CardMedia>
          <CardContent sx={{ margin: "auto", textAlign: "justify" }}>
            <Typography textAlign="justify" variant="body2">
              Основен фактор е източника на замърсяване .
              <br />
              Други определящи фактори за разхода за почистване на соларни панели са:
              <br />
              - местоположение
              <br />
              - вид конструкция
              <br />
              - начин на почистване (с четка, роторни четки, роботи за детайлно почистване)
              <br />
            </Typography>
          </CardContent>
          <Box>
          <Link to="./contacts">
            <Button
              sx={{ color: "#cd7700" }}
              type="submit"
              name="submit"
              aria-label="favorite"
            >
              Заявете оферта
            </Button>
            </Link>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
export default HowItWorks;
