import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import "./NavBar.css";
import { NavLink } from "react-router-dom";
import Logo from "../images/logo-stc.png";
import { IconButton, Menu, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from "@mui/icons-material/Facebook";

const Navigation = () => {
  const pages = [
    {
      title: "Начало",
      url: `/`,
    },
    {
      title: "Услуги",
      url: `/services`,
    },
    {
      title: "Препарати",
      url: `/agents`,
    },
    {
      title: "Контакти",
      url: `/contacts`,
    },
  ];
  const settings = [
    {
      title: "Контакти",
      url: `/contacts`,
    },
    {
      title: <FacebookIcon/>,
      url: `/contacts`,
    }
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" sx={{ background: "#ffffff" }}>
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box component="a" sx={{ display: { xs: "none", md: "flex" } }}>
            <NavLink to="/">
              <img className="logo-style" src={Logo} alt="logo" />
            </NavLink>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              width: "30%",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                mt:"3%",
              }}
            >
              <Box
                sx={{
                  width: "150px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {pages.map((page) => (
                  <Button>
                  <NavLink
                    key={page.index}
                    className={({ isActive }) =>
                      isActive ? "active-link" : "myLink"
                    }
                    onClick={handleCloseNavMenu}
                    to={page.url}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </NavLink>
                  </Button>
                ))}
              </Box>
            </Menu>
          </Box>
          <Box
            component="a"
            sx={{ display: { xs: "flex", md: "none" }, width: "80%", mt: "1%" }}
          >
            <NavLink to="/">
              <img className="logo-style" src={Logo} alt="logo" />
            </NavLink>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button>
                <NavLink
                  key={page.index}
                  to={page.url}
                  onClick={handleCloseNavMenu}
                  className={({ isActive }) =>
                    isActive ? "active-link" : "myLink"
                  }
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.title}
                </NavLink>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((item) => (
                <Button>
                  <NavLink
                    key={item.index}
                    onClick={handleCloseUserMenu}
                    className={({ isActive }) =>
                      isActive ? "active-link" : "myLink"
                    }
                  >
                    <Typography textAlign="center">{item.title}</Typography>
                  </NavLink>
                </Button>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navigation;
