import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import "./Services.css";
import "./Agents.css";
import { Link } from "react-router-dom";

const Services = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <Grid
        container
        minHeight="470px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="10%"
      >
        <Grid item xs={12} sm={8} md={4} lg={10} xl={10} className="card">
          <Card
            sx={{
              backgroundColor: "rgb(242, 240, 241)",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <CardHeader
              title="Продажба на сертифицирани препарати и техника за почистване на соларни панели"
              titleTypographyProps={{
                fontSize: 18,
              }}
              subheader={`Препарати ChemiTek и роботи за детайлно почистване SolarClean0`}
              subheaderTypographyProps={{
                fontSize: 14,
              }}
            />
            <Box className="card-content">
              <Box className="card-media-services">
                <CardMedia
                  component="img"
                  image="../images/service-1.avif"
                  height="250"
                  sx={{ margin: "auto" }}
                  alt="Препарати за почистване на соларни панели"
                ></CardMedia>
              </Box>
              <Box className="card-text">
                <CardContent sx={{ margin: "auto", textAlign: "justify" }}>
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    <b>ChemiTek</b> и <b>SolarClean0</b> предлагат за своите
                    клиенти сертифицирани и устройчиви решения за поддръжка на
                    соларни панели.
                    <br />
                    В секция Препарати ще намерите продуктовата гама на ChemiTek
                    и като официален дистрибутор, ние ще Ви снабдим в най-кратък
                    срок.
                    <br />
                    Лесно и бързо ще почистите замърсявания от прах, полени,
                    екскременти от птици, цимент, боя. Най-доброто решение за
                    поддръжка на новите Ви соларни панели е препарат антистатик
                    за соларни панели, както и препарат за хидрофобна защина за
                    соларни панели.
                    <br />
                    Водещи производители на соларни панели препоръчват
                    почистване с препаратите на ChemiTek.
                  </Typography>
                  <br />
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    При интерес относно роботите на SolarClean0, моля позвънете
                    ни на посочените телефони или използвайте контактната форма.
                  </Typography>
                  <br />
                </CardContent>
              </Box>
            </Box>
            <CardActions>
              <Link to="/agents">
                <Button
                  sx={{
                    margin: "auto",
                    color: "#273649",
                    borderColor: "#273649",
                    ":hover": {
                      bgcolor: "#273649",
                      borderColor: "#273649",
                      color: "white",
                    },
                  }}
                  variant="outlined"
                >
                  Още
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={10} xl={10} className="card">
          <Card
            sx={{
              backgroundColor: "rgb(242, 240, 241)",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <CardHeader
              title="Почистване на соларни панели от всякакъв вид замърсители"
              titleTypographyProps={{
                fontSize: 18,
              }}
              subheader={`Увеличаване на производителността на ФЕЦ`}
              subheaderTypographyProps={{
                fontSize: 14,
              }}
            />
            <Box className="card-content">
              <Box className="card-media-services">
                <CardMedia
                  component="img"
                  image="../images/service-2.avif"
                  height="250"
                  sx={{ margin: "auto" }}
                  alt="почистване на соларни панели"
                />
              </Box>
              <Box className="card-text">
                <CardContent sx={{ margin: "auto", textAlign: "justify" }}>
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    Най-добрият начин да увеличите ефективността на Вашата
                    фотоволтаична електроцентрала (ФЕЦ) е да извършвате редовни
                    почиствания и поддръжка на соларните панели. Използваме
                    специално предназначени препарати за почистване, за да се
                    увеличи производителността на соларните панели с 20% или
                    дори повече в някои случаи. С помощта на роботите за
                    детайлно почистване SolarClean0 постигаме максимални
                    резултати за кратки срокове.
                  </Typography>
                  <br />
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    Чрез абонаментна програма <b>"Лоялен клиент"</b> за редовно
                    почистване на соларни панели, можете да спестите от
                    разходите за цялостна поддръжка!
                  </Typography>
                  <br />
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                    justifyItems="left"
                  >
                    Доверете ни се!
                  </Typography>
                </CardContent>
              </Box>
            </Box>
            <CardActions>
              <Link to="../files/sunEnergy.pdf" target="_blank">
                <Button
                  sx={{
                    margin: "auto",
                    color: "#273649",
                    borderColor: "#273649",
                    ":hover": {
                      bgcolor: "#273649",
                      borderColor: "#273649",
                      color: "white",
                    },
                  }}
                  variant="outlined"
                >
                  Още
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={10} xl={10} className="card">
          <Card
            sx={{
              backgroundColor: "rgb(242, 240, 241)",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <CardHeader
              title="Третиране на зелени площи около соларните панели"
              titleTypographyProps={{
                fontSize: 18,
              }}
              subheader={`Поддръжка на терени около ФЕЦ`}
              subheaderTypographyProps={{
                fontSize: 14,
              }}
            />
            <Box className="card-content">
              <Box className="card-media-services">
                <CardMedia
                  component="img"
                  image="../images/service-3.avif"
                  height="250"
                  sx={{ margin: "auto" }}
                ></CardMedia>
              </Box>
              <Box className="card-text">
                <CardContent sx={{ margin: "auto", textAlign: "justify" }}>
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    Третиране на зелени площи, както и поддържането им чрез
                    затревяване са част от услугите, които може да предоставим.
                  </Typography>
                  <br />
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    Използваме специална техника, чрез която можем лесно, бързо
                    и без да се пореди конструкцията, да извършим тези
                    дейностти.
                  </Typography>
                  <br />
                </CardContent>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={4} lg={10} xl={10} className="card">
          <Card
            sx={{
              backgroundColor: "rgb(242, 240, 241)",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <CardHeader
              title="Термовизионно заснемане с индустриален дрон"
              titleTypographyProps={{
                fontSize: 18,
              }}
              subheader={`Качествено обследване с професионална техника`}
              subheaderTypographyProps={{
                fontSize: 14,
              }}
            />
            <Box className="card-content">
              <Box className="card-media-services">
                <CardMedia
                  component="img"
                  image="../images/service-4.avif"
                  height="250"
                  sx={{ margin: "auto" }}
                />
              </Box>
              <Box className="card-text">
                <CardContent sx={{ margin: "auto", textAlign: "justify" }}>
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    Интегрирането на дрон технологията напълно подобри
                    ефективността и точността на процеса на инспекция. Tермо
                    камерите могат да изследват големи площи соларни панели,
                    събирайки инфрачервени изображения с висока разделителна
                    способност само по време на един полет.
                  </Typography>
                  <br />
                  <Typography
                    sx={{ marginTop: "20px" }}
                    variant="h7"
                    color="black"
                  >
                    Чрез тази инспекция Вие ще добиете на 99% представа за това
                    как работят Вашите соларни панели относно -
                    производителност, цялост, нарушени клиетки и други.
                  </Typography>
                  <br />
                </CardContent>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Services;
